import React, {Component} from 'react';
import axios from 'axios';
import Helmet from 'react-helmet';

const initialState = {
  pin: '',
  rePin: '',
  complete: false,
  errorMap: null,
  loading: false,
  showPwd: false
}

class PinChange extends Component {
  state = initialState;

  componentDidMount() {

    // GA計測用データレイヤースクリプトを追加する
    window.addDataLayerScript('jmbauth_pin_change');
  }

  // [変更する]ボタン押下時処理
  pinSetting = () => {

    // Cookieからl_sidを取得する
    let l_sid = window.getCookieValue('l_sid');

    // アクションのレポート作成
    try{
      window.tsPlatform.drs.triggerActionEvent("account_details_change_pinchange", { correlationId: l_sid }).then((actionResponse) => {
        let actionToken = actionResponse.actionToken;
      });
    }catch(e){
    }

    const { pin, rePin } = this.state;

    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    // バリデーションチェックを行う
    if (pin === '') {
      this.setState({ errorMap: { ERROR: messageList.pinChange.M012_errorMessage001, errorId: ['pin'] }});
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
      // 処理を終了する。
      return;
    }

    if (pin !== rePin) {
      this.setState({ errorMap: { ERROR: messageList.pinChange.M013_errorMessage002, errorId: ['pin', 'rePin'] }, pin: '', rePin: ''
      });
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
      // 処理を終了する。
      return;
    }

    // PIN設定要求を行う
    try {
      // ローディング開始
      this.setState({ loading: true });

      // PINコード設定API
      axios
        .put( process.env.REACT_APP_URL_CO_SC_API_PIN_CHANGE,
          {
            pin: pin
          },  
          {headers: {'accept-language': window.changeLang(sessionStorage.getItem("lang")),
                     'Cache-Control': 'no-store',
                     'Pragma': 'no-cache'}}
        )
      .then( response => {

        this.setState({ complete: true, loading: false });  
        // GA計測用データレイヤースクリプトを追加する
        window.addDataLayerScript('jmbauth_pin_change_completion');   
      })
      .catch( error => {
        if ( error.response ) {
          if ( error.response.status === 400 ) {
            this.setState({ errorMap:
              { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.pinChange.M014_errorMessage003 },
              loading: false, pin: '', rePin: ''
            });
            // エラーメッセージ表示領域に遷移する
            window.location="#JS_error";
          }
          else if ( error.response.status === 401 ) {
            this.setState({ errorMap:
              { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.pinChange.M014_errorMessage003 },
              loading: false, pin: '', rePin: ''
            });
            // エラーメッセージ表示領域に遷移する
            window.location="#JS_error";
          }
          else if ( error.response.status === 500 ) {

            // エラー画面に遷移し、処理を終了する
            this.props.history.push({
              pathname:`${process.env.REACT_APP_CONTEXT_PATH}/error`
            });
          }
          else {
            this.setState({ errorMap:
              { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.pinChange.M014_errorMessage003 },
              loading: false, pin: '', rePin: ''
            });
            // エラーメッセージ表示領域に遷移する
            window.location="#JS_error";
          }
        }
        else {
          this.setState({ errorMap:
            { ERROR: messageList.pinChange.M014_errorMessage003 },
            loading: false, pin: '', rePin: ''
          });
          // エラーメッセージ表示領域に遷移する
          window.location="#JS_error";
        }
      });

    } catch ( err ) {
      console.error(err);
      this.setState({ errorMap:
        { ERROR: messageList.pinChange.M014_errorMessage003 },
        loading: false, pin: '', rePin: ''
      });
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
      
    } finally {
    }
  }

  // パスワード表示切り替え処理
  swhichPwdShow = e => {
    this.setState({ showPwd: e.currentTarget.checked });
  }

  handleChange = e => {
    this.setState({ [e.currentTarget.name]: e.currentTarget.value.trim() });
  }

  // 画面をクローズする
  close = e => {
    window.open('about:blank','_self').close();
  }

  render() {    

    // 文言リストを取得する
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    // PIN設定完了画面表示
    if (this.state.complete){
      return (
        <section>
          <Helmet
            title={messageList.pinChangeCompletion.M001_browserTitle} >
          </Helmet>

          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">{messageList.pinChangeCompletion.M002_pageTitle}</h1>
          </div>

          {/* 操作ボタン表示部 */}
          <div className="btn-wrap">
            <button type="button" name="__dummy__" className="btn-back" onClick={this.close}>{messageList.pinChangeCompletion.M003_close}</button>
          </div>

        </section>
      );
    }

    // PIN設定画面表示
    else {
      return (
        <section>
          <Helmet
            title={messageList.pinChange.M001_browserTitle} >
          </Helmet>

          { // Loadingモーダル表示
            this.state.loading &&
            <div id="loading" className="loading-modal"><img className="loading-img" src={`${process.env.PUBLIC_URL}/img/load.gif`} alt="loading"/></div>
          }
          
          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">{messageList.pinChange.M002_pageTitle}</h1>
          </div>

          <div id="JS_error">
          { // エラーメッセージ表示
            (this.state.errorMap && this.state.errorMap.ERROR) &&
            <div className="panel-attention">
              <h2 className="panel-attention_hdg">
                <span className="panel-attention_icon">
                  <img src={`${process.env.PUBLIC_URL}/img/icon_error.svg`} alt={messageList.pinChange.M003_errorIconText}/>
                </span>{messageList.pinChange.M004_errorBoxTitle}
              </h2>
              <p>{this.state.errorMap.ERROR}</p>
            </div>/* /panel-attention */
          }
          </div>

          <div className="panel-form">
            <div className="panel-form-inner">
              <div className="panel-form-inner_item">
                <form name="dummy" id="JS_jmbForm">

                  {/* 画面項目表示部 */}
                  <div className="panel-form-inner_parts">

                    {/* 電話用パスワード */}
                    <p className="form-label"><label htmlFor="LA_input-password-01">{messageList.pinChange.M005_pinPassword}</label></p>
                    <input type={ this.state.showPwd ? "text" : "password"} id="LA_input-password-01" 
                      className={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('pin') ? 
                      "form-txt js-form-validate-blank JS_jmbPass is-blank is-error" : "form-txt js-form-validate-blank JS_jmbPass is-blank"}
                      maxLength="6" placeholder="" 
                      aria-invalid={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('pin') ? "true" : "false"} 
                      name="pin" value={this.state.pin} onChange={this.handleChange}/>
                    
                    {/* 電話用パスワードの再入力 */}
                    <p className="form-label"><label htmlFor="LA_input-password-02">{messageList.pinChange.M006_rePinPassword}</label></p>
                    <input type={ this.state.showPwd ? "text" : "password"} id="LA_input-password-02" 
                      className={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('rePin') ? 
                      "form-txt js-form-validate-blank JS_jmbPass is-blank is-error" : "form-txt js-form-validate-blank JS_jmbPass is-blank"}
                      maxLength="6" placeholder="" 
                      aria-invalid={ this.state.errorMap && this.state.errorMap.errorId && this.state.errorMap.errorId.includes('rePin') ? "true" : "false"} 
                      name="rePin" value={this.state.rePin} onChange={this.handleChange}/>
                    
                    {/* パスワードを表示する */}
                    <span className="form-checkbox _block">
                      <input type="checkbox" id="LA_form-checkbox-01" name="__dummy__" 
                        aria-controls="LA_input-password-01 LA_input-password-02" onChange={this.swhichPwdShow}/>
                      <label htmlFor="LA_form-checkbox-01" className="form-checkbox_item is-blank">{messageList.pinChange.M007_showPassword}</label>
                    </span>
                  </div>

                  {/* 操作ボタン表示部 */}
                  <div className="btn-wrap">
                    <button type="button" name="__dummy__" className="btn" onClick={this.pinSetting}>{messageList.pinChange.M008_update}</button>
                  </div>

                </form>
              </div> {/* /panel-form-inner_item */}
            </div> {/* /panel-form-inner */}
          </div> 

          {/* 案内表示部 */}
          <div className="hdg-guide">
            <h2 className="hdg-guide_txt">{messageList.pinChange.M009_notices}</h2>
          </div>
          <ul className="list">            
            <li>{messageList.pinChange.M010_notice001}</li>
          </ul> 
        </section>
      ); 
    }
  }
}

export default PinChange