import React, {Component} from 'react';
import Helmet from 'react-helmet';

const initialState = {
}

class Error extends Component {
  state=initialState

  // 初期処理
  componentDidMount() {

    // 「GA計測用データレイヤースクリプト追加処理」を呼出す
    window.addDataLayerScript('jmbauth_error');
  }

  // 画面表示処理
  render() {

    // 文言リストを取得する
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));
    
    // URLのERROR_MESSAGEパラメータに「CO-AT3100」を含む場合
    if (this.props.params && this.props.params.ERROR_MESSAGE && this.props.params.ERROR_MESSAGE.indexOf('CO-AT3100') !== -1){
      return (
        <section>

          <Helmet
            title={messageList.error.M001_title}>
          </Helmet>

          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">{messageList.error.M001_title}</h1>
          </div>

          {/* エラーメッセージ */} 
          <div className="panel-attention">
            <h2 className="panel-attention_hdg">
              <span className="panel-attention_icon">
                <img src={`${process.env.PUBLIC_URL}/img/icon_error.svg`} alt={messageList.error.M002_errorIconText}/>
              </span>{messageList.error.M003_errorBoxTitle}
            </h2>
            <p>{messageList.error.M004_errorMessage1}</p>
          </div>

          {/* 操作ボタン表示部 */}
          <div className="btn-wrap">
            <a href="https://www.jal.com/index.html" className="btn-back">{messageList.error.M005_home}</a>
          </div>
        </section>
      )
    }
    // URLのERROR_MESSAGEパラメータに「UserIdentifiedKeyIsNotMatchException」を含む場合
    else if (this.props.params && this.props.params.ERROR_MESSAGE && this.props.params.ERROR_MESSAGE.indexOf('UserIdentifiedKeyIsNotMatchException') !== -1){
      return (
        <section>

          <Helmet
            title={messageList.error.M001_title}>
          </Helmet>

          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">{messageList.error.M001_title}</h1>
          </div>

          {/* エラーメッセージ */} 
          <div className="panel-attention">
            <h2 className="panel-attention_hdg">
              <span className="panel-attention_icon">
                <img src={`${process.env.PUBLIC_URL}/img/icon_error.svg`} alt={messageList.error.M002_errorIconText}/>
              </span>{messageList.error.M003_errorBoxTitle}
            </h2>
            <p>{messageList.error.M004_errorMessage2}</p>
          </div>

          {/* 操作ボタン表示部 */}
          <div className="btn-wrap">
            <a href="https://www.jal.com/index.html" className="btn-back">{messageList.error.M005_home}</a>
          </div>
        </section>
      )
    }
    // URLのERROR_MESSAGEパラメータに「RecordNotFoundException」を含む場合
    else if (this.props.params && this.props.params.ERROR_MESSAGE && this.props.params.ERROR_MESSAGE.indexOf('RecordNotFoundException') !== -1){
      return (
        <section>

          <Helmet
            title={messageList.error.M001_title}>
          </Helmet>

          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">{messageList.error.M001_title}</h1>
          </div>

          {/* エラーメッセージ */} 
          <div className="panel-attention">
            <h2 className="panel-attention_hdg">
              <span className="panel-attention_icon">
                <img src={`${process.env.PUBLIC_URL}/img/icon_error.svg`} alt={messageList.error.M002_errorIconText}/>
              </span>{messageList.error.M003_errorBoxTitle}
            </h2>
            <p>{messageList.error.M004_errorMessage3}</p>
          </div>

          {/* 操作ボタン表示部 */}
          <div className="btn-wrap">
            <a href="https://www.jal.com/index.html" className="btn-back">{messageList.error.M005_home}</a>
          </div>
        </section>
      )
    }
    // 「通知先が登録されない」エラー画面（OTP入力画面から遷移）
    else if (this.props.location.state && 
        (this.props.location.state.errorScreen === 'ADDRESS_UNREGISTERED_OTPLOGIN')){
        return (
          <section>

            <Helmet
              title="エラー画面/System error - JAL" 
              htmlAttributes={{
                lang: "ja",
              }}>
            </Helmet>

            {/* 画面タイトル表示部 */}
            <div className="hdg-l1">
              <h1 className="hdg-l1_txt">以下をご確認ください/<span lang="en">Please review the below</span></h1>
            </div>

            {/* 画面項目表示部 */}
            <div className="box-txt">
            <p>メールアドレスが登録されておりません。メールアドレスのご登録については以下をご参照の上、お問い合わせください。</p>
            <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/contact/" className="link-txt">JALマイレージバンク日本地区会員事務局/JALマイレージバンクセンター</a>
            </div>
            <div className="box-txt">
            <p lang="en">E-mail address not registered. Please refer to the following and contact us.</p>
            <a href="https://www.jal.co.jp/jp/en/jmb/jmb-login/contact/" className="link-txt" lang="en">JAL Mileage Bank Center (Japan Region)</a>
            </div>

            {/* 操作ボタン表示部 */}
            <div className="btn-wrap">
              <a href="https://www.jal.com/index.html" className="btn-back">ホーム画面に戻る/<span lang="en">Home</span></a>
            </div>
          </section>
        )
    }
    // 「通知先が登録されない」エラー画面（パスワードリセット画面から遷移）
    else if (this.props.location.state && 
        (this.props.location.state.errorScreen === 'ADDRESS_UNREGISTERED_PWDRESET')){
        return (
          <section>

            <Helmet
              title="エラー画面/System error - JAL" 
              htmlAttributes={{
                lang: "ja",
              }}>
            </Helmet>

            {/* 画面タイトル表示部 */}
            <div className="hdg-l1">
              <h1 className="hdg-l1_txt">以下をご確認ください/<span lang="en">Please review the below</span></h1>
            </div>

            {/* 画面項目表示部 */}
            <div className="box-txt">
              <p>ワンタイムパスワードをお送りするメールアドレスが正しく登録されていない可能性があります。<br></br>
              以下のリンクよりメールアドレスを登録してください。</p>
            </div>
	
            <div class="btn-wrap">
              <div class="lyt-btn-line"><a href="https://www121.jal.co.jp/JmbWeb/EmailRegMemInput_ja.do" class="btn r-arrow btn-WH sp-disnon">メールアドレスを登録する</a></div>
              <div class="lyt-btn-line"><a href="https://sp5971.jal.co.jp/JmbSp/EmailRegMemInput_ja.do" class="btn r-arrow btn-WH pc-disnon">メールアドレスを登録する</a></div>
            </div>

            <div class="box-txt mgt-16">
              <p lang="en">The email address to which your one-time password will be sent may have been registered incorrectly.<br></br>
              Please use the link below to register your email address.</p>
            </div>

            <div class="btn-wrap">
              <div class="lyt-btn-line"><a href="https://www121.jal.co.jp/JmbWeb/EmailRegMemInput_en.do" class="btn r-arrow btn-WH" lang="en">Register email address</a></div>
            </div>

            {/* 操作ボタン表示部 */}
            <div className="btn-wrap">
              <a href="https://www.jal.com/index.html" className="btn-back">ホーム画面に戻る/<span lang="en">Home</span></a>
            </div>
          </section>
        )
    }
    // 「戻るボタン押下」エラー画面
    else if (this.props.location.state && 
      (this.props.location.state.errorScreen === 'BACKWARD_BUTTON_CLICKED')){
      return (
        <section>

          <Helmet
            title="エラー画面/System error - JAL" 
            htmlAttributes={{
              lang: "ja",
            }}>
          </Helmet>

          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">システムエラー/<span lang="en">System error</span></h1>
          </div>

          {/* 画面項目表示部 */}
          <div className="box-txt">
            <p>ブラウザの戻るボタンは使用出来ません。<br></br>
            お使いのブラウザを閉じてから再度お試しください。</p>
            <p lang="en">Please do not use your browser's Back button.<br></br>
            You will need to close and restart your browser.</p>
          </div>

          {/* 操作ボタン表示部 */}
          <div className="btn-wrap">
            <a href="https://www.jal.com/index.html" className="btn-back">ホーム画面に戻る/<span lang="en">Home</span></a>
          </div>
        </section>
      )
  }
    // 通常のエラー画面
    else {
      return (
        <section>

          <Helmet
            title="エラー画面/System error - JAL" 
            htmlAttributes={{
              lang: "ja",
            }}>
          </Helmet>

          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">システムエラー/<span lang="en">System error</span></h1>
          </div>

          {/* 画面項目表示部 */}
          <div className="box-txt">
            <p>大変申し訳ございません。システムエラーが発生しました。<br></br>
            もう一度、ホーム画面に戻ってお試しください。</p>
            <p lang="en">A system error has occurred. We apologize for any inconvenience.<br></br>
            Please return to the home page and try again.</p>
          </div>

          {/* 操作ボタン表示部 */}
          <div className="btn-wrap">
            <a href="https://www.jal.com/index.html" className="btn-back">ホーム画面に戻る/<span lang="en">Home</span></a>
          </div>
        </section>
      )
    }
  }
}

export default Error