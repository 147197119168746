import React, {Component} from 'react';
import Helmet from 'react-helmet';

const initialState = {
}

class DenyError extends Component {
  state=initialState

  // 初期処理
  componentDidMount() {

    // 「GA計測用データレイヤースクリプト追加処理」を呼出す
    window.addDataLayerScript('jmbauth_smsotp_operation_refusal_error');
  }

  // 画面表示処理
  render() {

    // 文言リストを取得する
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));
    
    return (
      <section>

        {/* ブラウザタイトル */}
        <Helmet
          title={messageList.denyError.M001_browserTitle} >
        </Helmet>

        {/* 画面タイトル表示部 */}
        <div className="hdg-l1">
          <h1 className="hdg-l1_txt">{messageList.denyError.M002_pageTitle}</h1>
        </div>

        {/* 操作ボタン表示部 */}
        <div className="btn-wrap">
          <a href="https://www.jal.com/index.html" className="btn-back">{messageList.denyError.M003_home}</a>
        </div>
      </section>
    );
  }
}

export default DenyError