import React, {Component} from 'react';
import Helmet from 'react-helmet';

const initialState = {
}

class MobilenumberRegistGuide extends Component {
  state=initialState

  // 初期処理
  componentDidMount() {

    // 「GA計測用データレイヤースクリプト追加処理」を呼出す
    window.addDataLayerScript('jmbauth_smsotp_mobile_registration_line');
  }

  // 通知先の登録ボタン押下時処理
  regist = () => {
    window.open(`${process.env.REACT_APP_CONTEXT_PATH}/mobilenumber_change?design=`
       + sessionStorage.getItem('design') + "&lang=" + sessionStorage.getItem('lang') + "&registTransType=REGIST_GUID");
  }    

  // 画面表示処理
  render() {

    // 文言リストを取得する
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));
    
    return (
      <section>

        {/* ブラウザタイトル */}
        <Helmet
          title={messageList.mobilenumberRegistGuide.M001_browserTitle} >
        </Helmet>

        {/* 画面タイトル表示部 */}
        <div className="hdg-l1">
          <h1 className="hdg-l1_txt">{messageList.mobilenumberRegistGuide.M002_pageTitle}</h1>
        </div>

        {/* ガイドメッセージ表示部 */}
        <div className="box-txt">
          <p>{messageList.mobilenumberRegistGuide.M003_guideMessage001}</p>
        </div>

        {/* 操作ボタン表示部 */}
        <div className="btn-wrap">
          <div className="lyt-btn-line">
           <a href="https://www.jal.com/index.html" className="btn-back">{messageList.mobilenumberRegistGuide.M004_home}</a>
           <button type="submit" name="__dummy__" className="btn" onClick={this.regist}>{messageList.mobilenumberRegistGuide.M005_regist}</button>
         </div>
        </div>
      </section>
    );
  }
}

export default MobilenumberRegistGuide