import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Helmet from 'react-helmet';

const initialState = {
  snsSiteId: '',
  email: '',
  redirectUrl: '',
  complete: false,
  errorMap: null,
  loading: false,
}

class SnsRelation extends Component {
  state = initialState;

  // 初期表示処理
  componentDidMount() {

    // SNS情報参照
    this.getSnsUserInfo();
  }

  // SNS情報参照
  getSnsUserInfo = () => {

    const { SNS_ASSOCIATE_TID } = this.props.params;

    // 文言リストを取得する
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    try {

    // ローディング開始
    this.setState({ loading: true });
    
    // SNS情報参照
    axios
      .get( process.env.REACT_APP_URL_CO_SC_API_SNS_USER_INFO,
        {
          params: {
            snsAssociateTid: SNS_ASSOCIATE_TID
          },
          headers: {
            'accept-language': window.changeLang(sessionStorage.getItem("lang")),
            'Cache-Control': 'no-store',
            'Pragma': 'no-cache'
          }
        }
       )
      .then( response => {
        const snsSiteId = response.data.snsSiteId;
        let snsEmail;
        if(snsSiteId === 'line'){
          snsEmail = response.data.email == null ? '' : response.data.email;
        }else{
          snsEmail = response.data.email;
        }
        const email = snsEmail;
        this.setState({
                        snsSiteId: snsSiteId,
                        email: email,
                        errorMap: '',
                        loading: false
                      });
        // GA計測用データレイヤースクリプトを追加する
        window.addDataLayerScript('jmbauth_sns_confirmation');
      })
      .catch( error => {
        if ( error.response ) {
          if ( error.response.status === 403 ) {
            this.setState({ errorMap:
              { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.snsRelation.M014_errorMessage001 },
              loading: false
              });
          }
          else if ( error.response.status === 500 ) {

            // エラー画面に遷移し、処理を終了する
            this.props.history.push({
              pathname:`${process.env.REACT_APP_CONTEXT_PATH}/error`
            });
          }
          else {
            this.setState({ errorMap:
              { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.snsRelation.M014_errorMessage001 },
              loading: false
            });
          }
        }
        else {
          this.setState({ errorMap:
            { ERROR: messageList.snsRelation.M014_errorMessage001 },
            loading: false
            });
        }
        // エラーメッセージ表示領域に遷移する
        window.location="#JS_error";
      })
    } catch ( err ) {
      console.error(err);
      this.setState({ errorMap:
      { ERROR: messageList.snsRelation.M014_errorMessage001},
      loading: false
      });
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
    } finally {
    }
  }

  // [連携する]ボタン押下時処理
  snsRelation = () => {

    // Cookieからl_sidを取得する
    let l_sid = window.getCookieValue('l_sid');

    // アクションのレポート作成
    try{
      window.tsPlatform.drs.triggerActionEvent("account_details_change_snsrelation_a", { correlationId: l_sid }).then((actionResponse) => {
        let actionToken = actionResponse.actionToken;
      });
    }catch(e){
    }

    const { SNS_ASSOCIATE_TID } = this.props.params;

    // 文言リストを取得する
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    try {
      // ローディング開始
      this.setState({ loading: true });

      // SNS認証紐付けAPI
      axios
        .post( process.env.REACT_APP_URL_CO_SC_API_SNS_ASSOCIATE,
          {
            snsAssociateTid: SNS_ASSOCIATE_TID
          },
          {headers: {'accept-language': window.changeLang(sessionStorage.getItem("lang")),
                     'Cache-Control': 'no-store',
                     'Pragma': 'no-cache'}}
        )
        .then( response => {
          this.setState({ errorMap: null, 
                          redirectUrl: response.data.redirectUrl, 
                          complete: true, 
                          loading: false 
                        });
          // GA計測用データレイヤースクリプトを追加する
          window.addDataLayerScript('jmbauth_sns_completion');

          // セッションストレージにSNSログイン情報を削除する
          if (sessionStorage.getItem('SNSLOGIN')) {
            sessionStorage.removeItem('SNSLOGIN')
          }
        })
        .catch( error => {
          if ( error.response ) {
            if ( error.response.status === 400 ) {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.snsRelation.M014_errorMessage001 },
                loading: false
              });
            }
            else if ( error.response.status === 401 ) {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.snsRelation.M014_errorMessage001 },
                loading: false
              });
            }
            else if ( error.response.status === 403 ) {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.snsRelation.M014_errorMessage001 },
                loading: false
              });
            }
            else if ( error.response.status === 500 ) {
              // セッションストレージにSNSログイン情報を削除する
              if (sessionStorage.getItem('SNSLOGIN')) {
                sessionStorage.removeItem('SNSLOGIN')
              }
              // エラーメッセージ表示領域に遷移する
              this.props.history.push({
                pathname:`${process.env.REACT_APP_CONTEXT_PATH}/error`
              });
            }
            else {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.snsRelation.M014_errorMessage001 },
                loading: false
              });
            }
          }
          else {
            this.setState({ errorMap:
              { ERROR: messageList.snsRelation.M014_errorMessage001 },
              loading: false
            });
          }
          // エラーメッセージ表示領域に遷移する
          window.location="#JS_error";
        })
    } catch ( err ) {
      console.error(err)
      this.setState({ errorMap:
        { ERROR: messageList.snsRelation.M014_errorMessage001},
        loading: false
      });
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
    } finally {
    }

  }

  render() {

    // 文言リストを取得する
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    console.log(this.state);

    // SNS認証紐付け完了画面
    if (this.state.complete){
      return (
        <section>

          {/* ブラウザタイトル */}
          <Helmet
            title={messageList.snsRelationCompletion.M001_browserTitle} >
          </Helmet>

          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">{messageList.snsRelationCompletion.M002_pageTitle}</h1>
          </div>

          {/* 操作ボタン表示部 */}
          <div className="btn-wrap">
            <div>
              <a href={this.state.redirectUrl} className="btn-back">{messageList.snsRelationCompletion.M003_back}</a>
            </div>
          </div>
        </section>
      );
    }

    // SNS認証紐付け確認画面
    else{
      return (
        <section className="section">

          {/* ブラウザタイトル */}
          <Helmet
            title={messageList.snsRelation.M001_browserTitle} >
          </Helmet>

          { // Loadingモーダル表示
            this.state.loading &&
            <div id="loading" className="loading-modal"><img className="loading-img" src={`${process.env.PUBLIC_URL}/img/load.gif`} alt="loading"/></div>
          }

          {/* 画面タイトル表示部 */}
          <div className="hdg-l1">
            <h1 className="hdg-l1_txt">{messageList.snsRelation.M002_pageTitle}</h1>
          </div>

          {/* ガイド表示部 */}
          <p>{messageList.snsRelation.M003_guideMessage001}</p>
          <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/snsid/" className="link-txt _other" target="otherwindow">
            <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={messageList.snsRelation.M005_newWindowIcon} className="icon-blank"/>
              {messageList.snsRelation.M004_guideMessage002}</a>

          <div id="JS_error">
          { // エラーメッセージ表示
          (this.state.errorMap && this.state.errorMap.ERROR) &&
            <div className="panel-attention">
              <h2 className="panel-attention_hdg"><span className="panel-attention_icon"><img src={`${process.env.PUBLIC_URL}/img/icon_error.svg`} alt={messageList.snsRelation.M006_errorIconText}/></span>{messageList.snsRelation.M007_errorBoxTitle}</h2>
              <p>{this.state.errorMap.ERROR}</p>
            </div>/* /panel-attention */
          }
          </div>

          <div className="panel-form other-id-link-wrap">
            <div className="panel-form-inner">
              <div className="panel-form-inner_item">
                <div className="dis-fl-item_contents">

                  {/* 画面項目表示部 */}
                  <div className="ic-user-wrap">
                    <div className={"img-ic" + (this.state.snsSiteId === 'line' ? " line-ic" : "")}>
                      <div className={"img-ic-inner" + (this.state.snsSiteId === 'line' ? " line-ic-inner" : "")}>
                        {(this.state.snsSiteId === 'google') &&
                        <img src={`${process.env.PUBLIC_URL}/img/logo_google.png`} alt={messageList.snsRelation.M008_snsGoogle}/>}
                        {(this.state.snsSiteId === 'yahoo') &&
                        <img src={`${process.env.PUBLIC_URL}/img/logo_yahoo.png`} alt={messageList.snsRelation.M009_snsYahoo}/>} 
                        {(this.state.snsSiteId === 'line') &&
                        <img src={`${process.env.PUBLIC_URL}/img/logo_line.png`} alt={messageList.snsRelation.M015_snsLine}/>} 
                      </div>
                    </div>
                    <p>{this.state.email}</p>
                  </div>

                  {/* 操作ボタン表示部 */}
                  <div className="btn-wrap btn-clm2-l">
                    <div>
                      <button type="button" className="btn non-arrow" onClick={this.snsRelation}>{messageList.snsRelation.M010_link}</button>
                    </div>
                    <div>
                      <Link className="btn-back" to="sns_relation_select" >
                        {messageList.snsRelation.M011_back}
                      </Link>
                    </div>
                  </div>
                </div>
              </div> {/* /panel-form-inner_item */}
            </div> {/* /panel-form-inner */}
          </div>

          {/* 案内表示部 */}
          <div className="hdg-guide">
            <h2 className="hdg-guide_txt">{messageList.snsRelation.M012_notices}</h2>
          </div>
          <ul className="list">
            <li>{messageList.snsRelation.M013_notice001}</li>
          </ul>
        </section>
      );
    }
  }
}

export default withRouter(SnsRelation)