import React, {Component} from 'react';
import axios from 'axios';
import Fingerprintjs2 from 'fingerprintjs2';
import RiskOtpLogin from './RiskOtpLogin';
import SpecificOtpLogin from './SpecificOtpLogin'
import Login from './Login';

const initialState = {
  screen: '',
  resAuthenticated: '',
  fingerPrint: '',
  errorMap: null,
  loading : false,
  autoLoginStateFlg: false,
  autoLoginId: '',
  maskmail: '',
  snsHidden: false,
  webViewFlag: false,
  maskphonenumber: '',
  tabChangeFlg: false,
  RPFlag: false
}

class CommonLogin extends Component {

  state = initialState;

  // 初期表示処理
  componentDidMount () {

    // ブラウザバックから遷移する場合
    if (window.performance.navigation.type === 2) {
      // セッションストレージにSNSログイン情報を削除する
      if (sessionStorage.getItem('SNSLOGIN')) {
        sessionStorage.removeItem('SNSLOGIN')
      }
      // エラー画面に遷移し、処理を終了する
      this.props.history.push({
        pathname:`${process.env.REACT_APP_CONTEXT_PATH}/error`,
        state:{errorScreen: 'BACKWARD_BUTTON_CLICKED'}
      });          
    }
    // 正常遷移の場合
    else{

      const { AUTH_TYPE, AUTHENTICATED } = this.props.params;
      const info = {
          AUTH_TYPE: AUTH_TYPE
      }

      // AUTH_TYPEが「AUTH_SOCIAL_JOIN」の場合、エラー画面を表示
      if ( AUTH_TYPE === process.env.REACT_APP_AUTH_TYPE_JOIN ){
        this.props.history.push({
          pathname:`${process.env.REACT_APP_CONTEXT_PATH}/error`
        });
      } else {
        // ログイン後にSNS認証紐付け確認画面に遷移する場合
        // SNSログインボタンは表示しない
        if ( AUTHENTICATED && window.endsWith(AUTHENTICATED, '/sns_relation')) {
          this.setState({ snsHidden: true });
        }     

        var userAgent = window.navigator.userAgent.toLowerCase();    
        if(userAgent.indexOf('trident') !== -1 || userAgent.indexOf('msie') !== -1) {
          import('url-search-params-polyfill').then(() => {
            // フィンガープリント取得
            let options = {excludes: {userAgent: true, enumerateDevices: true, pixelRatio: true, doNotTrack: true, fontsFlash: true}};
            Fingerprintjs2.getV18( options, (result)=>{
              this.setState({ fingerPrint: result });
              // 取得後にlogin前処理呼出し
              this.beforeLogin(info);
            });
          });
        }
        else {
          // フィンガープリント取得
          let options = {excludes: {userAgent: true, enumerateDevices: true, pixelRatio: true, doNotTrack: true, fontsFlash: true}};
          Fingerprintjs2.getV18( options, (result)=>{
            this.setState({ fingerPrint: result });
            // 取得後にlogin前処理呼出し
            this.beforeLogin(info);
          });
        }

        // WebViewから呼び出された場合にはGoogleでログインボタンを非表示とする
        let winUserAgent = window.navigator.userAgent
        let userAgentExcludeList = process.env.REACT_APP_USERAGENT_EXCLUDE_LIST.split(',');
        for (let data of userAgentExcludeList) {
          if (winUserAgent.indexOf(data) !== -1) {
            this.setState({ webViewFlag: true });
            break;
          } 
        }
        
        // 周辺システムから呼び出された場合にはLINEでログインボタンを非表示とする
        let clientIdExcludeList = process.env.REACT_APP_CLIENTID_EXCLUDE_LIST.split(',');
        for (let data of clientIdExcludeList) {
          data = 'client_id=' + data;
          if (AUTHENTICATED.indexOf(data) !== -1) {
            this.setState({ RPFlag: true });
            break;
          } 
        }
      }
    }
  }

  // login前処理
  beforeLogin = (info) => {

    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    const { fingerPrint } = this.state;
    const { SITE_ID, AUTHENTICATED, MESSAGE_AUTH} = this.props.params;
    const { AUTH_TYPE, snsid, ACTION_TOKEN } = info;

    try {
      // tidクリア
      document.cookie = String(`${process.env.REACT_APP_COOKIE_KEY_TID}=;max-age=0;domain=${process.env.REACT_APP_COOKIE_DOMAIN};path=${process.env.REACT_APP_COOKIE_PATH};`);

      // パラメータ作成
      const params = new URLSearchParams();
      params.append('AUTH_TYPE',  AUTH_TYPE);
      params.append('SITE_ID', SITE_ID);
      params.append('MESSAGE_AUTH', MESSAGE_AUTH);
      params.append('AUTHENTICATED', AUTHENTICATED);
      params.append('snsid', snsid);
      params.append('Fingerprint', fingerPrint);
      // SNSログインの場合、アクショントークンを設定
      if(AUTH_TYPE === process.env.REACT_APP_AUTH_TYPE && ACTION_TOKEN){
          params.append('action_token', ACTION_TOKEN);
      }

      // ローディング開始
      this.setState({ loading: true });

      // 認証要求
      axios({
              method: 'POST',
              url: process.env.REACT_APP_URL_CO_SC_API_LOGIN,
              data: params,
              headers: {'accept-language': window.changeLang(sessionStorage.getItem('lang')),
                        'Cache-Control': 'no-store',
                        'Pragma': 'no-cache'}
            })       
        .then( response => {
          const resultCode = response.data.resultCode;
          const authType = response.data.authType;
          const authenticated = response.data.authenticated;
          const error_message = response.data.error_message;
          const maskmail = response.data.maskmail;
          const maskphonenumber = response.data.maskphonenumber;
          if (resultCode){    
            // ログイン画面表示 or 次認証あり
            if (resultCode==='CO-SC0001' || resultCode==='CO-SC0002'){
              if (authType){
                if (authType === 'AUTH_THREEKEY' || authType === 'AUTH_THREEKEY_LOW') {
                  // オートログイン認証を行う
                    this.autoLogin();
                    // 「GA計測用データレイヤースクリプト追加処理」を呼出す
                    if (this.state.snsHidden) {
                      window.addDataLayerScript('jmbauth_login');
                    }
                    else {
                      window.addDataLayerScript('jmbauth_login_sns');
                    }
                }
                else if(authType==='AUTH_SOCIAL'){
                  const redirecturi = response.data.redirecturi;
                  if(snsid === 'line'){
                    this.setState({
                      loading: false,
                      tabChangeFlg: true
                    });
                  }
                  window.location.href = redirecturi;
                }
                else {
                  this.setState({ screen: authType, 
                    errorMap: '',
                    loading: false,
                    maskmail: maskmail,
                    maskphonenumber: maskphonenumber
                  });
                }  
              }
              else{
                this.setState({ errorMap:
                  { ERROR: messageList.login.M025_errorMessage002 },
                  loading: false
                });
                // エラーメッセージ表示領域に遷移する
                window.location="#JS_error";  
              }
            }
            // 全認証終了
            else if (resultCode==='CO-SC0003'){
              if (authenticated){
                // 遷移先URLに遷移し、処理を終了する
                window.location.href = authenticated;
              }
              else{
                this.setState({ errorMap:
                  { ERROR: messageList.login.M025_errorMessage002 },
                  loading: false
                });
                // エラーメッセージ表示領域に遷移する
                window.location="#JS_error";  
              }
            }
            // 利用者のワンタイムパスワード通知先が登録されていない
            else if (resultCode==='CS-AT1021'){
              // エラー画面に遷移し、処理を終了する
              this.props.history.push({
                pathname:`${process.env.REACT_APP_CONTEXT_PATH}/error`,
                state:{errorScreen: 'ADDRESS_UNREGISTERED_OTPLOGIN'}
              });
            }
            // CO-AT5000が返却された場合
            else if (resultCode==='CO-AT5000'){
              // エラー一覧で定義したシステムエラーを返却する
              this.setState({ errorMap:
                { ERROR: messageList.login.M034_errorMessage003 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
            // CO-AT5005が返却された場合
            else if (resultCode==='CO-AT5005'){
              // UserIdentifiedKeyIsNotMatchExceptionの文字列が含まれる場合
              if (error_message && error_message.indexOf('UserIdentifiedKeyIsNotMatchException') !== -1){
                // エラー一覧で定義したシステムエラーを返却する
                this.setState({ errorMap:
                  { ERROR: messageList.login.M039_errorMessage008},
                  loading: false
                });
              }
              else {
                // エラー一覧で定義したシステムエラーを返却する
                this.setState({ errorMap:
                  { ERROR: messageList.login.M035_errorMessage004 },
                  loading: false
                });
              }
              
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
            // CO-AT3000が返却された場合
            else if (resultCode==='CO-AT3000'){
              // エラー一覧で定義したシステムエラーを返却する
              this.setState({ errorMap:
                { ERROR: messageList.login.M036_errorMessage005 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
            // CO-AT5001が返却された場合
            else if (resultCode==='CO-AT5001'){
              // エラー一覧で定義したシステムエラーを返却する
              this.setState({ errorMap:
                { ERROR: messageList.login.M037_errorMessage006 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
            // CO-AT5004が返却された場合
            else if (resultCode==='CO-AT5004'){
              // エラー一覧で定義したシステムエラーを返却する
              this.setState({ errorMap:
                { ERROR: messageList.login.M038_errorMessage007 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
            // CO-AT6008が返却された場合
            else if (resultCode==='CO-AT6008' && this.state.screen !== 'AUTH_RISK_ONETIME' && this.state.screen !== 'AUTH_ONETIME'){
              // エラー一覧で定義したシステムエラーを返却する
              this.setState({ errorMap:
                { ERROR: messageList.login.M052_errorMessage009 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
            // CS-AT1031が返却された場合
            else if (resultCode==='CS-AT1031'){
              // 操作拒否エラー画面に遷移する
              this.props.history.push({
                pathname:`${process.env.REACT_APP_CONTEXT_PATH}/deny_error`,
              });
            }
            // CS-AT1032が返却された場合
            else if (resultCode==='CS-AT1032'){
              // 携帯電話番号登録導線画面に遷移する
              this.props.history.push({
                pathname:`${process.env.REACT_APP_CONTEXT_PATH}/mobilenumber_register_guide`,
              });
            }
            else{
              let errorMessageStr;
              errorMessageStr = error_message;
              // CO-AT3100の場合、Exceptionの文字列をブランクに置き換える
              if (resultCode==='CO-AT3100' && errorMessageStr){
                errorMessageStr = errorMessageStr
                    .replace('jp.co.nec.nc7000_3a.co.authn.exception.CompositeAuthnPluginFailedException: jp.co.nec.nc7000_3a.co.authn.exception.AuthenticationPluginFailedException:', '')
              }

              if(authType){
                if (errorMessageStr){
                  this.setState({ screen: authType, 
                                  errorMap: { ERROR: errorMessageStr },
                                  loading: false
                  });
                }
                else{
                  this.setState({ screen: authType, 
                    errorMap:
                    { ERROR: messageList.login.M025_errorMessage002 },
                    loading: false
                  });                    
                }
              }
              else{
                if (errorMessageStr){
                  this.setState({ errorMap:
                    { ERROR: errorMessageStr },
                    loading: false
                  });
                }
                else {
                  this.setState({ errorMap:
                    { ERROR: messageList.login.M025_errorMessage002 },
                    loading: false
                  });
                }
              }
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
          }
          else{
            this.setState({ errorMap:
              { ERROR: messageList.login.M025_errorMessage002 },
              loading: false
            });
            // エラーメッセージ表示領域に遷移する
            window.location="#JS_error";
          }
        })
        .catch( error => {
          if ( error.response ) {
            if ( error.response.status === 400 ) {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.login.M025_errorMessage002 },
                loading: false

              });
            }
            else {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.login.M025_errorMessage002 },
                loading: false
              });
            }
          }
          else {
            this.setState({ errorMap:
              { ERROR: messageList.login.M025_errorMessage002 },
              loading: false
            });
        }
        // エラーメッセージ表示領域に遷移する
        window.location="#JS_error";
      })
    } catch ( err ) {
      console.error(err);
      this.setState({ errorMap:
        { ERROR: messageList.login.M025_errorMessage002},
        loading: false
      });
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
    } finally {
    }
  }

  // オートログイン認証
  autoLogin = () => {

    try {
      // オートログイン認証セッションCookieチェックAPI
      axios({
            method: 'GET',
            url: process.env.REACT_APP_URL_CO_SC_API_CHECKAUTOSESSION,
            data: null,
            headers: {'accept-language': window.changeLang(sessionStorage.getItem('lang')),
                      'Cache-Control': 'no-store',
                      'Pragma': 'no-cache'}
          }) 
        .then ( response => {         
          this.setState({ screen: 'AUTH_THREEKEY',
                          autoLoginId: response.data[0].id,
                          autoLoginStateFlg: true,
                          errorMap: '',
                          loading: false
                        });
        })
        .catch( error => {
          this.setState({ 
            screen: 'AUTH_THREEKEY',
            errorMap: '',
            loading: false
          });
        })
    } catch( err ) {
      this.setState({ 
        screen: 'AUTH_THREEKEY',
        errorMap: '',
        loading: false
      });
    } finally {
    }
  }

  // [ログイン]ボタン押下時処理
  commonLogin  = (info) => {
    const { fingerPrint } = this.state;
    const { ORG_ID, ID, PWD, LowLevelSessionFlg, ACTION_TOKEN } = info;   

    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    // バリデーションチェックを行う
    if (this.state.screen === 'AUTH_RISK_ONETIME' || this.state.screen === 'AUTH_ONETIME') {
      if (PWD === '') {
        this.setState({ errorMap: { ERROR: messageList.otpLogin.M014_errorMessage001, errorId: 'otp'}});
        // エラーメッセージ表示領域に遷移する
        window.location="#JS_error";
        return;
      }
    }
    else {
      if (ID === '') {
        this.setState({ errorMap: { ERROR: messageList.login.M024_errorMessage001, errorId: 'id'}});
        // エラーメッセージ表示領域に遷移する
        window.location="#JS_error";
        return;
      }

      if (PWD === '') {
        this.setState({ errorMap: { ERROR: messageList.login.M024_errorMessage001, errorId: 'password'}});
        // エラーメッセージ表示領域に遷移する
        window.location="#JS_error";
        return;
      }
    }

    // ログイン要求を行う
    try {
      // パラメータ作成
      const params = new URLSearchParams();
      params.append('ORG_ID', ORG_ID);
      // お得意様番号が7桁の場合は先頭に00でパディングして9桁で送信する
      if (ID && ID.length === 7) {
        params.append('ID', ID.padStart(9, '00'));
      }
      else {
        params.append('ID', ID);
      }
      params.append('PWD', PWD);
      params.append('Fingerprint', fingerPrint);
      params.append('lowLevelSessionFlg', LowLevelSessionFlg);
      ACTION_TOKEN && params.append('action_token', ACTION_TOKEN);

      // ローディング開始
      this.setState({ loading: true });
      
      // 認証メイン処理
      axios({
              method: 'POST',
              url: process.env.REACT_APP_URL_CO_SC_API_LOGIN,
              data: params,
              headers: {'accept-language': window.changeLang(sessionStorage.getItem('lang')),
                        'Cache-Control': 'no-store',
                        'Pragma': 'no-cache'}
            }) 
        .then( response => {
          const resultCode = response.data.resultCode;
          const authenticated = response.data.authenticated;
          const authType = response.data.authType; 
          const error_message = response.data.error_message;        
          const maskmail = response.data.maskmail;
          const maskphonenumber = response.data.maskphonenumber;
          
          if (resultCode){
            // ログイン画面表示 or 次認証あり
            if (resultCode==='CO-SC0001' || resultCode==='CO-SC0002'){
              if (authType){
                // Cookie設定(ログイン画面でログインボタン押下時のみ設定する)
                if (ID) {
                  document.cookie = String(`JalAcc=${PWD.length >= 8 ? 'True' : 'False'};domain=${process.env.REACT_APP_COOKIE_DOMAIN};path=${process.env.REACT_APP_COOKIE_PATH};`);
                  sessionStorage.setItem('loginId', ID);
                }
                this.setState({ screen: authType,
                                errorMap: '',
                                loading: false,
                                maskmail: maskmail,
                                maskphonenumber: maskphonenumber
                              });                
              }
              else{
                this.setState({ errorMap:
                  { ERROR: messageList.login.M025_errorMessage002 },
                  loading: false
                });
                // エラーメッセージ表示領域に遷移する
                window.location="#JS_error";  
              }
            }
            // 全認証終了
            else if (resultCode==='CO-SC0003'){
              if (authenticated){
                // Cookie設定(ログイン画面でログインボタン押下時のみ設定する)
                if (ID) {
                  document.cookie = String(`JalAcc=${PWD.length >= 8 ? 'True' : 'False'};domain=${process.env.REACT_APP_COOKIE_DOMAIN};path=${process.env.REACT_APP_COOKIE_PATH};`);
                  sessionStorage.setItem('loginId', ID);
                }
                // 遷移先URLに遷移し、処理を終了する
                window.location.href = authenticated;
              }
              else{
                this.setState({ errorMap:
                  { ERROR: messageList.login.M025_errorMessage002 },
                  loading: false
                });
                // エラーメッセージ表示領域に遷移する
                window.location="#JS_error";  
              }
            }
            // 利用者のワンタイムパスワード通知先が登録されていない
            else if (resultCode==='CS-AT1021'){
              // エラー画面に遷移し、処理を終了する
              this.props.history.push({
                pathname:`${process.env.REACT_APP_CONTEXT_PATH}/error`,
                state:{errorScreen: 'ADDRESS_UNREGISTERED_OTPLOGIN'}
              });
            }
            // CO-AT5000が返却された場合
            else if (resultCode==='CO-AT5000'){
              // エラー一覧で定義したシステムエラーを返却する
              this.setState({ errorMap:
                { ERROR: messageList.login.M034_errorMessage003 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
            // CO-AT5005が返却された場合
            else if (resultCode==='CO-AT5005'){
              // UserIdentifiedKeyIsNotMatchExceptionの文字列が含まれる場合
              if (error_message && error_message.indexOf('UserIdentifiedKeyIsNotMatchException') !== -1){
                // エラー一覧で定義したシステムエラーを返却する
                this.setState({ errorMap:
                  { ERROR: messageList.login.M039_errorMessage008 },
                  loading: false
                });
              }
              else {
                // エラー一覧で定義したシステムエラーを返却する
                this.setState({ errorMap:
                  { ERROR: messageList.login.M035_errorMessage004 },
                  loading: false
                });
              }
              
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
            // CO-AT3000が返却された場合
            else if (resultCode==='CO-AT3000'){
              // エラー一覧で定義したシステムエラーを返却する
              this.setState({ errorMap:
                { ERROR: messageList.login.M036_errorMessage005 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
            // CO-AT5001が返却された場合
            else if (resultCode==='CO-AT5001'){
              // エラー一覧で定義したシステムエラーを返却する
              this.setState({ errorMap:
                { ERROR: messageList.login.M037_errorMessage006 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
            // CO-AT5004が返却された場合
            else if (resultCode==='CO-AT5004'){
              // エラー一覧で定義したシステムエラーを返却する
              this.setState({ errorMap:
                { ERROR: messageList.login.M038_errorMessage007 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
            // CO-AT6008が返却された場合
            else if (resultCode==='CO-AT6008' && this.state.screen !== 'AUTH_RISK_ONETIME' && this.state.screen !== 'AUTH_ONETIME'){
              // エラー一覧で定義したシステムエラーを返却する
              this.setState({ errorMap:
                { ERROR: messageList.login.M052_errorMessage009 },
                loading: false
              });
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
            // CS-AT1031が返却された場合
            else if (resultCode==='CS-AT1031'){
              // 操作拒否エラー画面に遷移する
              this.props.history.push({
                pathname:`${process.env.REACT_APP_CONTEXT_PATH}/deny_error`,
              });
            }
            // CS-AT1032が返却された場合
            else if (resultCode==='CS-AT1032'){
              // 携帯電話番号登録導線画面に遷移する
              this.props.history.push({
                pathname:`${process.env.REACT_APP_CONTEXT_PATH}/mobilenumber_register_guide`,
              });
            }
            else{
              let errorMessageStr;
              errorMessageStr = error_message;
              // CO-AT3100の場合、Exceptionの文字列をブランクに置き換える
              if (resultCode==='CO-AT3100' && errorMessageStr){
                errorMessageStr = errorMessageStr
                    .replace('jp.co.nec.nc7000_3a.co.authn.exception.CompositeAuthnPluginFailedException: jp.co.nec.nc7000_3a.co.authn.exception.AuthenticationPluginFailedException:', '')
              }

              if (errorMessageStr){
                this.setState({ errorMap:
                  { ERROR: errorMessageStr },
                  loading: false
                });
              }
              else {
                this.setState({ errorMap:
                  { ERROR: messageList.login.M025_errorMessage002 },
                  loading: false
                });
              }
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
          }
          else if ( response.request.responseURL && response.request.responseURL.indexOf('CO-AT3100') !== -1){
            // エラー画面に遷移し、処理を終了する
            window.location.href = response.request.responseURL;
          }
          else{
            this.setState({ errorMap:
              { ERROR: messageList.login.M025_errorMessage002 },
              loading: false
            });
            // エラーメッセージ表示領域に遷移する
            window.location="#JS_error";
          }
        })
        .catch( error => {
          if ( error.response ) {
            if ( error.response.status === 400 ) {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.login.M025_errorMessage002 },
                loading: false
              });
            }
            else {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.login.M025_errorMessage002 },
                loading: false
              });
            }
          }
          else {
            this.setState({ errorMap:
              { ERROR: messageList.login.M025_errorMessage002 },
              loading: false
            });
          }
          // エラーメッセージ表示領域に遷移する
          window.location="#JS_error";
        });
    } catch ( err ) {
      console.error(err);
      this.setState({ errorMap:
        { ERROR: messageList.login.M025_errorMessage002},
        loading: false
      });
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
    } finally {
    }
  }

  // [ログアウト]ボタン押下時処理
  logout = () => {

    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    try {
      // パラメータ作成
      const params = new URLSearchParams();
      params.append('design', sessionStorage.getItem('design'));
      params.append('lang', sessionStorage.getItem('lang'));

      // ローディング開始
      this.setState({ loading: true });

      // ログアウトAPI
      axios({
              method: 'POST',
              url: process.env.REACT_APP_URL_CO_SC_API_LOGOUT,
              data: params,
              headers: {'accept-language': window.changeLang(sessionStorage.getItem('lang')),
                        'Cache-Control': 'no-store',
                        'Pragma': 'no-cache'}
            }) 
        .then(response => {
          window.location.href = response.request.responseURL || process.env.REACT_APP_LOGOUT_URL + response.config.data;
        })
        .catch(error => {
          console.error(error);
          if ( error.response ) {
            this.setState({ errorMap:
              { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.login.M025_errorMessage002 },
              loading: false
            });
          }
          else {
            this.setState({ errorMap:
              { ERROR: messageList.login.M025_errorMessage002 },
              loading: false
            });
          }
          // エラーメッセージ表示領域に遷移する
          window.location="#JS_error";
        })
    } catch ( err ) {
      console.error(err);
      this.setState({ errorMap:
        { ERROR: messageList.login.M025_errorMessage002},
        loading: false
      });
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
    } finally {
    }
  }

  // 画面表示処理
  render() {

    // リスクベースOTP入力画面
    if (this.state.screen === 'AUTH_RISK_ONETIME'){
      return (
        <RiskOtpLogin loginMethod={this.commonLogin}
                      errMap={this.state.errorMap}
                      loading={this.state.loading}
                      maskmail={this.state.maskmail}
                      maskphonenumber={this.state.maskphonenumber}/>
      );
    }
    // 特定サービスOTP入力画面
    else if (this.state.screen === 'AUTH_ONETIME' || this.state.screen === 'AUTH_SMS_ONETIME'){
      return (
        <SpecificOtpLogin loginMethod={this.commonLogin}
                          errMap={this.state.errorMap}
                          loading={this.state.loading}
                          maskmail={this.state.maskmail}
                          maskphonenumber={this.state.maskphonenumber}/>
      );
    }
    // ログイン画面
    else if (this.state.screen === 'AUTH_THREEKEY' || this.state.screen === 'AUTH_THREEKEY_LOW' || (this.state.errorMap && this.state.errorMap.ERROR)) {
      return (
        <Login loginMethod={this.commonLogin} 
               errMap={this.state.errorMap}
               loading={this.state.loading}
               autoLoginId={this.state.autoLoginId} 
               autoLoginStateFlg={this.state.autoLoginStateFlg}
               guestUrl={this.props.params.GUEST_URL}
               logoutMethod={this.logout}
               snslogin={this.beforeLogin}
               snsHidden={this.state.snsHidden}
               webViewFlag={this.state.webViewFlag}
               tabChangeFlg={this.state.tabChangeFlg}
               RPFlag={this.state.RPFlag}/>
      );
    }
    // 空白のロード画面
    else {
      return(
        <section className="section">
          { // Loadingモーダル表示
            this.state.loading &&
            <div id="loading" className="loading-modal"><img className="loading-img" src={`${process.env.PUBLIC_URL}/img/load.gif`} alt="loading"/></div>
          }
        </section>
      );
    }      
  }
}

export default CommonLogin