import React, {Component} from "react";
import { withRouter } from 'react-router-dom'
import axios from 'axios';
import Fingerprintjs2 from 'fingerprintjs2';
import Helmet from 'react-helmet';

const initialState={
  google: false,
  yahoo: false,
  line: false,
  fingerPrint: '',
  errorMap: null,
  loading: false,
  ready: false,
  initFlg: true,
  tabChangeFlg: false
}

class SNSSetting extends Component {
  state = initialState;

  componentDidMount() {
    // SNS連携参照API
    this.drawing(this.props.params.ERROR_MESSAGE);

    // フィンガープリント取得
	  let options = {excludes: {userAgent: true, enumerateDevices: true, pixelRatio: true, doNotTrack: true, fontsFlash: true}};
	  Fingerprintjs2.getV18( options, (result)=>{
		  this.setState({ fingerPrint: result });
	  });
    
  }

  // 画面表示処理（初期、解除後再表示）
  drawing = (queryErrorMessage) => {

    // 文言リストを取得する
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    try{

      axios
        .post( process.env.REACT_APP_URL_CO_SC_API_SNS_AUTHN,{},
            {headers: {'accept-language': window.changeLang(sessionStorage.getItem('lang')),
                      'Cache-Control': 'no-store',
                      'Pragma': 'no-cache'}}
          )
        .then( response => {

          // SNS認証連携情報リスト
          const snsSiteIdList = response.data.snsauthnlist;

          let snsSiteRelation = {
            google: false,
            yahoo: false,
            line: false
          };

          for(let i of snsSiteIdList){
            if(i.snsSiteId===process.env.REACT_APP_SNSID_GOOGLE){
              snsSiteRelation.google = true;
            }
            if(i.snsSiteId===process.env.REACT_APP_SNSID_YAHOO){
              snsSiteRelation.yahoo = true;
            }
            if(i.snsSiteId===process.env.REACT_APP_SNSID_LINE){
              snsSiteRelation.line = true;
            }
          }
          
          this.setState({
            // クエリパラメータにエラーメッセージがある場合付与
            errorMap:{ ERROR: queryErrorMessage ? queryErrorMessage : "" },
            google: snsSiteRelation.google,
            yahoo: snsSiteRelation.yahoo,
            line: snsSiteRelation.line,
            loading: false,
            ready: true
          });

          if (this.state.initFlg) {
            // 「GA計測用データレイヤースクリプト追加処理」を呼出す
            window.addDataLayerScript('jmbauth_sns_alignment');
            window.initDialog();
            this.setState({
              initFlg: false
            });
          }
        })
        .catch( error => {
          if ( error.response ) {
            if( error.response.status === 400 ) {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.snsSetting.M024_errorMessage001 },
                loading: false
              });
            }else if( error.response.status === 401 ) {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.snsSetting.M024_errorMessage001 },
                loading: false
              });
            }else if( error.response.status === 500 ) {
              // エラー画面に遷移し、処理を終了する
              this.props.history.push({
                pathname:`${process.env.REACT_APP_CONTEXT_PATH}/error`
              });
            }else{
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.snsSetting.M024_errorMessage001 },
                loading: false
              });
            }
          }else {
            this.setState({ errorMap:
              { ERROR: messageList.snsSetting.M024_errorMessage001 },
              loading: false
            });
          }
          // エラーメッセージ表示領域に遷移する
          window.location="#JS_error";
        });

    }catch(err){
      this.setState({ errorMap:
        { ERROR: messageList.snsSetting.M024_errorMessage001 },
        loading: false
      });
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
    }finally{
    }
  }

  // SNS連携処理（login前処理をAUTH_TYPE=AUTH_SOCIAL_JOINで呼出す）
  cooperateBtn = (snsid) => {

    // Cookieからl_sidを取得する
    let l_sid = window.getCookieValue('l_sid');

    // アクションのレポート作成
    try{
      window.tsPlatform.drs.triggerActionEvent("account_details_change_snsrelation_b", { correlationId: l_sid }).then((actionResponse) => {
        let actionToken = actionResponse.actionToken;
      });
    }catch(e){
    }

    const messageauth = process.env.REACT_APP_MESSAGE_AUTH;
    const authenticated = process.env.REACT_APP_CLIENT_SERVER_URL + process.env.REACT_APP_AUTHENTICATED_PATH;
    const siteid   = process.env.REACT_APP_SITE_ID;
    const authtype = process.env.REACT_APP_AUTH_TYPE_JOIN;
    const fingerPrint = this.state.fingerPrint;
    const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN;

    // 文言リストを取得する
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    try {
      // tidクリア
      document.cookie = String(`${process.env.REACT_APP_COOKIE_KEY_TID}=;max-age=0;domain=${cookieDomain};path=${process.env.REACT_APP_COOKIE_PATH};`);

      // パラメータ作成
      const params = new URLSearchParams();
      params.append('AUTH_TYPE', authtype);
      params.append('SITE_ID', siteid);
      params.append('MESSAGE_AUTH', messageauth);
      params.append('AUTHENTICATED', authenticated);
      params.append('snsid', snsid);
      params.append('Fingerprint', fingerPrint);

      // カスタムヘッダーを付与
      const headers = {'auth-request-approval': '',
                      'accept-language': window.changeLang(sessionStorage.getItem("lang")),
                      'Cache-Control': 'no-store',
                      'Pragma': 'no-cache'};

      // ローカルストレージに画面デザイン、画面表示言語を設定する
      localStorage.setItem('design',sessionStorage.getItem('design'));
      localStorage.setItem('lang',sessionStorage.getItem('lang'));

      // ローディング開始
      this.setState({ loading: true });
    
      // 認証要求URL生成
      const requestUrl = process.env.REACT_APP_URL_CO_SC_API_LOGIN;

      // 認証要求
      axios
        .post( requestUrl, params, {headers:headers} )
        .then( response => {
          const resultCode    = response.data.resultCode;
          const authType      = response.data.authType;
          const authenticated = response.data.authenticated;
          const error_message = response.data.error_message;
          if (resultCode){
            // ログイン画面表示 or 次認証あり
            if (resultCode==='CO-SC0001' || resultCode==='CO-SC0002'){
              if (authType){
                if(authType==='AUTH_SOCIAL_JOIN'){
                  const redirecturi = response.data.redirecturi;
                  // アクションのレポート作成を行うため次画面遷移を1秒遅延させる
                  setTimeout(() => {
                    if(snsid === 'line'){
                      this.setState({
                        loading: false,
                        tabChangeFlg: true
                      });
                    }
                    window.location.href = redirecturi;
                  },1000);
                }
                else{
                  // エラー
                  this.setState({ errorMap:
                    { ERROR: messageList.snsSetting.M024_errorMessage001 },
                    loading: false
                  });
                  // エラーメッセージ表示領域に遷移する
                  window.location="#JS_error";
                }
              }
              else{
                this.setState({ errorMap:
                  { ERROR: messageList.snsSetting.M024_errorMessage001 },
                  loading: false
                });
                // エラーメッセージ表示領域に遷移する
                window.location="#JS_error";
              }
            }
            // 全認証終了
            else if (resultCode==='CO-SC0003'){
              if (authenticated){
                // アクションのレポート作成を行うため次画面遷移を1秒遅延させる
                setTimeout(() => {
                  window.location.href = authenticated;
                },1000);
              }
              else{
                this.setState({ errorMap:
                  { ERROR: messageList.snsSetting.M024_errorMessage001 },
                  loading: false
                });
                // エラーメッセージ表示領域に遷移する
                window.location="#JS_error";
              }
            }
            // CO-AT5005が返却された場合
            else if (resultCode==='CO-AT5005'){
              // 通常システムエラーメッセージを表示する
              this.setState({ errorMap:
                { ERROR: messageList.snsSetting.M024_errorMessage001 },
                loading: false
              });
              
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
            else{
              if (error_message){
                this.setState({ errorMap: { ERROR: error_message },
                                loading: false
                                });
              }
              else{
                this.setState({ errorMap:
                  { ERROR: messageList.snsSetting.M024_errorMessage001 },
                  loading: false
                });
              }
              // エラーメッセージ表示領域に遷移する
              window.location="#JS_error";
            }
          }
          else{
            this.setState({ errorMap:
              { ERROR: messageList.snsSetting.M024_errorMessage001 },
              loading: false
            });
            // エラーメッセージ表示領域に遷移する
            window.location="#JS_error";
          }
        })
        .catch( error => {
          if ( error.response ) {
            if ( error.response.status === 400 ) {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.snsSetting.M024_errorMessage001 },
                loading: false
              });
            }
            else {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.snsSetting.M024_errorMessage001 },
                loading: false
              });
            }
          }
          else {
            this.setState({ errorMap:
              { ERROR: messageList.snsSetting.M024_errorMessage001 },
              loading: false
            });
          }
          // エラーメッセージ表示領域に遷移する
          window.location="#JS_error";
        })
    } catch ( err ) {
      console.error(err);
      this.setState({ errorMap:
        { ERROR: messageList.snsSetting.M024_errorMessage001 },
        loading: false
      });
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
    } finally {
    }
  }

  // [解除]ボタン押下時処理
  releaseBtn = (site) => {

    // Cookieからl_sidを取得する
    let l_sid = window.getCookieValue('l_sid');

    // アクションのレポート作成
    try{
      window.tsPlatform.drs.triggerActionEvent("account_details_change_snsrelation_b", { correlationId: l_sid }).then((actionResponse) => {
        let actionToken = actionResponse.actionToken;
      });
    }catch(e){
    }

    // 文言リストを取得する
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    try{
      // ローディング開始
      this.setState({ loading: true });

      // SNS連携削除API
      axios
        .delete( process.env.REACT_APP_URL_CO_SC_API_SNS_DELETE, 
          {data: {snsSiteId: site},
          headers: {'accept-language': window.changeLang(sessionStorage.getItem("lang")),
          'Cache-Control': 'no-store',
          'Pragma': 'no-cache'}}
        )
        .then( response => {
          window.JLJS_closeModal();
          this.drawing('');
        })
        .catch( error => {
          if ( error.response ) {
            if ( error.response.status === 400 ) {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.snsSetting.M024_errorMessage001 },
                loading: false
              });
            }
            else if ( error.response.status === 401 ) {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.snsSetting.M024_errorMessage001 },
                loading: false
              });
            }
            else if ( error.response.status === 500 ) {
              // エラー画面に遷移し、処理を終了する
              this.props.history.push({
                pathname:`${process.env.REACT_APP_CONTEXT_PATH}/error`
              });

            }
            else {
              this.setState({ errorMap:
                { ERROR: error.response.data.errorMessage ? error.response.data.errorMessage : messageList.snsSetting.M024_errorMessage001 },
                loading: false
              });
            }
          }
          else {
            this.setState({ errorMap:
              { ERROR: messageList.snsSetting.M024_errorMessage001 },
              loading: false
            });
          }
          // 解除dialogを閉じる
          window.JLJS_closeModal();
          // エラーメッセージ表示領域に遷移する
          window.location="#JS_error";
        });

    } catch ( err ) {
      console.error(err);
      this.setState({ errorMap:
        { ERROR: messageList.snsSetting.M024_errorMessage001 },
        loading: false
      });
      // 解除dialogを閉じる
      window.JLJS_closeModal();
      // エラーメッセージ表示領域に遷移する
      window.location="#JS_error";
    } finally {
    }
  }

  // 画面をクローズする
  close = e => {
    window.open('about:blank','_self').close();
  }

  //表示
  render(){

    // 文言リストを取得する
    let messageList = JSON.parse(sessionStorage.getItem('messageList'));

    //紐付け情報画面
    return(
      <section className="section">

        {/* ブラウザタイトル */}
        <Helmet
          title={messageList.snsSetting.M001_browserTitle} >
        </Helmet>

        { // Loadingモーダル表示
          this.state.loading &&
          <div id="loading" className="loading-modal"><img className="loading-img" src={`${process.env.PUBLIC_URL}/img/load.gif`} alt="loading"/></div>
        }
        
        { // LINEタブ遷移モーダル表示
          this.state.tabChangeFlg &&
          <div id="tabChange" className="tab-change-modal">
            <span className="tab-change-message">
              リダイレクトしています…<br></br>
              終了後は画面を閉じてください<br></br>
              <br></br>
              Redirecting…<br></br>
              Please close this screen when finished.
            </span>
          </div>
        }

        {/* 画面タイトル表示部 */}
        <div className="hdg-l1">
          <h1 className="hdg-l1_txt">{messageList.snsSetting.M002_pageTitle}</h1>
        </div>

        {/* ガイド表示部 */}
        <p>{messageList.snsSetting.M003_guideMessage001}<br></br>{messageList.snsSetting.M004_guideMessage002}</p>
        <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/snsid/" className="link-txt _other" target="otherwindow">
          <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={messageList.snsSetting.M008_newWindowIcon} className="icon-blank"/>
          {messageList.snsSetting.M005_guideMessage003}</a>
        <p className="mgt-24">{messageList.snsSetting.M006_guideMessage004}</p>
        <a href="https://www.jal.co.jp/jp/ja/jmb/jmb-login/snsid/policy/" className="link-txt _other" target="otherwindow">
          <img src={`${process.env.PUBLIC_URL}/img/icon_blank_rd.svg`} alt={messageList.snsSetting.M008_newWindowIcon} className="icon-blank"/>
          {messageList.snsSetting.M007_guideMessage005}</a>
	
        <div id="JS_error">
        { // エラーメッセージ表示
        (this.state.errorMap && this.state.errorMap.ERROR) &&
          <div className="panel-attention">
            <h2 className="panel-attention_hdg"><span className="panel-attention_icon"><img src={`${process.env.PUBLIC_URL}/img/icon_error.svg`} alt={messageList.snsSetting.M009_errorIconText}/></span>{messageList.snsSetting.M010_errorBoxTitle}</h2>
            <p>{this.state.errorMap.ERROR}</p>
          </div>/* /panel-attention */
        }
        </div>

        {/* 画面項目表示部 */}
        { this.state.ready &&
          <div className="panel-form other-id-link-wrap link-cancel-wrap">
            <div className="panel-form-inner">
              {/* google */}
              <div className="panel-form-inner_item">
                <div className="ic-link-btn-wrap">
                  <div className="img-ic">
                    <div className="img-ic-inner">
                      <img src={`${process.env.PUBLIC_URL}/img/logo_google.png`} alt={messageList.snsSetting.M011_snsGoogle}/>
                    </div>
                  </div>
                  {!this.state.google &&
                  <div className="link-btn-wrap" aria-hidden="false">
                    <p className="non-link-tx">{messageList.snsSetting.M013_nolinked}</p>
                    <div className="btn-wrap">
                      <div>
                        <button type="button" className="btn non-arrow"
                          onClick = {()=> this.cooperateBtn(process.env.REACT_APP_SNSID_GOOGLE)}>
                          {messageList.snsSetting.M015_cooperate}
                        </button>
                      </div>
                    </div>
                  </div>}
                  {this.state.google &&
                  <div className="link-btn-wrap" aria-hidden="false">
                    <p>{messageList.snsSetting.M014_linked}</p>
                    <div className="btn-wrap">
                      <div>
                        <button type="button" className="btn non-arrow btn-WH JS_modalBtn" aria-controls="WA_modal_01">
                          {messageList.snsSetting.M016_release}
                        </button>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>{/* /panel-form-inner_item */}

              {/* Yahoo！Japan */}
              <div className="panel-form-inner_item">
                <div className="ic-link-btn-wrap">
                  <div className="img-ic">
                    <div className="img-ic-inner">
                      <img src={`${process.env.PUBLIC_URL}/img/logo_yahoo.png`} alt={messageList.snsSetting.M012_snsYahoo}/>
                    </div>
                  </div>
                  {!this.state.yahoo &&
                  <div className="link-btn-wrap" aria-hidden="false">
                    <p className="non-link-tx">{messageList.snsSetting.M013_nolinked}</p>
                    <div className="btn-wrap">
                      <div>
                        <button type="button" className="btn non-arrow"
                          onClick = {()=> this.cooperateBtn(process.env.REACT_APP_SNSID_YAHOO)}>
                          {messageList.snsSetting.M015_cooperate}
                        </button>
                      </div>
                    </div>
                  </div>}
                  {this.state.yahoo &&
                  <div className="link-btn-wrap" aria-hidden="false">
                    <p>{messageList.snsSetting.M014_linked}</p>
                    <div className="btn-wrap">
                      <div>
                        <button type="button" className="btn non-arrow btn-WH JS_modalBtn" aria-controls="WA_modal_02">
                          {messageList.snsSetting.M016_release}
                        </button>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>{/* /panel-form-inner_item */}

              {/* LINE */}
              <div className="panel-form-inner_item">
                <div className="ic-link-btn-wrap">
                  <div className="img-ic line-ic">
                    <div className="img-ic-inner line-ic-inner">
                      <img src={`${process.env.PUBLIC_URL}/img/logo_line.png`} alt={messageList.snsSetting.M027_snsLine}/>
                    </div>
                  </div>
                  {!this.state.line &&
                  <div className="link-btn-wrap" aria-hidden="false">
                    <p className="non-link-tx">{messageList.snsSetting.M013_nolinked}</p>
                    <div className="btn-wrap">
                      <div>
                        <button type="button" className="btn non-arrow"
                          onClick = {()=> this.cooperateBtn(process.env.REACT_APP_SNSID_LINE)}>
                          {messageList.snsSetting.M015_cooperate}
                        </button>
                      </div>
                    </div>
                  </div>}
                  {this.state.line &&
                  <div className="link-btn-wrap" aria-hidden="false">
                    <p>{messageList.snsSetting.M014_linked}</p>
                    <div className="btn-wrap">
                      <div>
                        <button type="button" className="btn non-arrow btn-WH JS_modalBtn" aria-controls="WA_modal_03">
                          {messageList.snsSetting.M016_release}
                        </button>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>{/* /panel-form-inner_item */}

              <div className="btn-wrap btn-back-wrap">
                <div><button type="button" className="btn-back" onClick={this.close}>{messageList.snsSetting.M017_close}</button></div>
              </div>
            </div>{/* /panel-form-inner */} 
          </div>
        }

        {/* google連携解除確認ダイアログ */}
        <div id="WA_modal_01" className="modal JS_modal JS_viewHeight" role="dialog" aria-hidden="true" tabIndex="-1">
          <div className="modal__inner JS_viewHeight">
            <div className="modalPanel JS_modalPanel">
              <div className="modalPanel__inner">
                  <p>{messageList.snsSetting.M018_dialogTitle001}</p>
                  <div className="btn-wrap">
                  <div><button type="button" className="btn non-arrow" onClick={()=> this.releaseBtn(process.env.REACT_APP_SNSID_GOOGLE)}>
                    {messageList.snsSetting.M020_dialogRelease}</button>
                  </div>
                  </div>
                  <button type="button" className="modalPanel__close JS_modalClose">
                    <img src={`${process.env.PUBLIC_URL}/img/icon_close_modal.svg`}
                      alt={messageList.snsSetting.M021_dialogClose}/>
                  </button>
              </div>
            </div>
          </div>
        </div>
        {/* yahoo連携解除確認ダイアログ */}
        <div id="WA_modal_02" className="modal JS_modal JS_viewHeight" role="dialog" aria-hidden="true" tabIndex="-1">
          <div className="modal__inner JS_viewHeight">
            <div className="modalPanel JS_modalPanel">
              <div className="modalPanel__inner">
                  <p>{messageList.snsSetting.M019_dialogTitle002}</p>
                  <div className="btn-wrap">
                  <div><button type="button" className="btn non-arrow" onClick={()=> this.releaseBtn(process.env.REACT_APP_SNSID_YAHOO)}>
                    {messageList.snsSetting.M020_dialogRelease}</button>
                  </div>
                  </div>
                  <button type="button" className="modalPanel__close JS_modalClose">
                    <img src={`${process.env.PUBLIC_URL}/img/icon_close_modal.svg`}
                      alt={messageList.snsSetting.M021_dialogClose}/>
                  </button>
              </div>
            </div>
          </div>
        </div>
        {/* line連携解除確認ダイアログ */}
        <div id="WA_modal_03" className="modal JS_modal JS_viewHeight" role="dialog" aria-hidden="true" tabIndex="-1">
          <div className="modal__inner JS_viewHeight">
            <div className="modalPanel JS_modalPanel">
              <div className="modalPanel__inner">
                  <p>{messageList.snsSetting.M028_dialogTitle003}</p>
                  <div className="btn-wrap">
                  <div><button type="button" className="btn non-arrow" onClick={()=> this.releaseBtn(process.env.REACT_APP_SNSID_LINE)}>
                    {messageList.snsSetting.M020_dialogRelease}</button>
                  </div>
                  </div>
                  <button type="button" className="modalPanel__close JS_modalClose">
                    <img src={`${process.env.PUBLIC_URL}/img/icon_close_modal.svg`}
                      alt={messageList.snsSetting.M021_dialogClose}/>
                  </button>
              </div>
            </div>
          </div>
        </div>
        <div className="overlay JS_overlay"></div>
        <div className="hdg-guide">
          <h2 className="hdg-guide_txt">{messageList.snsSetting.M022_notices}</h2>
        </div>
        <ul className="list">           
          <li>{messageList.snsSetting.M023_notice001}</li>
          <li>{messageList.snsSetting.M025_notice002}<br></br>{messageList.snsSetting.M026_notice003}</li>
        </ul>
     </section>
    );
  }
}

export default withRouter(SNSSetting)